#Explore{
    display: flex;
    justify-content: center;
    overflow-x:hidden ;
    position: relative;
    
}
#left{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* border: 1px solid #0070C0 ; */
    transition: all ease-in-out .2s;
    /* padding-right: 30px; */
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 25%;
    bottom: 0;
    left: 0;
    margin-left: 2rem;
}
#right{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* border: 1px solid #0070C0 ; */
    transition: all ease-in-out .2s;
    /* padding-right: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 25%;
    bottom: 0;
    right: 0;
    margin-right: 2rem;
}
#right:hover, #left:hover{
    box-shadow: 1rem 1rem 11.5rem -1.3rem rgba(0,0,0,0.75); 
    transition: all ease-in-out .2s;   
    background-color: white;
}

#Explore h2{
    font-size: 2.9rem;
}

#Explore .container{
    width:80vw;    
    padding-top: 2rem;
    display: flex;
    /* justify-content: flex-end; */
    flex-wrap: wrap;
    box-sizing: border-box;
}

#Explore .cards{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    /* margin: 50px 0px; */
    white-space:nowrap;
    overflow-x: scroll;
    height: 45rem;
    
}
#Explore .card{
    flex: 0 0 30%;
    white-space: normal;
    height: 27rem;

}
/* width */
::-webkit-scrollbar {
    width: 0;
  }
  
.specialization{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    padding-bottom:2rem ;
}
.specialization h2{
    flex: 1;
    font-size: 2.9rem;
}
.specialization p{
    flex: 4;
    font-size: 1.4rem;
}
/* .banner{
    width: 100%;
    margin: 50px 0px;
}
.banner img{
    width: 100%;
} */
@media only screen and (min-width: 0) and (max-width:768px){
    #Explore h2{
        font-size: 2.2rem;
        margin:auto;
    }
    #Explore .cards{
        height: 45rem;
    
    }
    #Explore .card{
        flex: 0 0 70%;
        height: 27rem;
        white-space: normal;

    }
    #right,#left{
        top: 30%;
    }
    .specialization{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .specialization h2{
        font-size: 2.2rem;
    }

    
    
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {     #Explore .card{
        flex: 0 0 50%;
        white-space: normal;
    }
    .specialization{
        flex-direction: column;
        text-align: center;
        height: 25rem;
    }
    
    

}