.card{
    /* height: 250px;
    width: 250px; */
    margin-right: 2rem;
    box-sizing: border-box;
    padding: 0 2.6rem;
    padding-top: 3.2rem;
    border-radius:0.5rem;
    transition: all ease-in-out .2s;
      

}
.card img{
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    /* background-color: rgb(0,112,192, 0.05); */
    transition: all ease-in .2s;
}
.card h4{
    font-size: 1.8rem;
    font-weight: bold;
}
.card p{
    font-size: 1.4rem;
}
.card:hover{
    box-shadow: 0rem 0rem 4.5rem -0.6rem #00000021;
    transition: all ease-in .2s;
    overflow: visible;
}
.card--wrapper{
    display: flex;
    justify-content: space-between;
}
