.navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: white;
    width: 100vw;
    box-sizing: border-box;
}

.navbar .container{
    width:80vw;   
    display: flex;    justify-content: space-between;
    align-items: center;
}
.navbar .container h3 a{
    font-size: 2.5rem;
    font-weight: 700;
    color: #0070C0;
    cursor: pointer;
    text-decoration: none;
}
.logo, .hamburger{
    height: 100%;
}
#nav--items ul{
    display: flex;
    list-style: none;
    cursor: pointer;
    gap: 4rem;
}
.nav--item a{
    text-decoration: none;
    font-size: 1.4rem;
    color: #000000;
    text-align: center;
    font-weight: 400;
    transition: all ease-out .2s ;

}
.hamburger{
    display: none;
    cursor: pointer;
}
.nav--item a:hover{
    text-decoration: none;
    font-size: 1.6rem;
    color: #0070C0;
    font-weight: 500;
    transition: all ease-in .2s ;
}
.nav--item a:hover{
    color: #0070C0;
}
.hamburger-react{
    display: none;
}
@media only screen and (max-width: 430px){    
    .navbar{
        padding:1.5rem 0rem;
        height: auto;

    }   
    .navbar h3{
        font-size: 2rem;
        font-weight: 700;
        color: #0070C0;
        cursor: pointer;
    }
    .navbar .container{
        flex-wrap: wrap;
        height: auto;
    }
    .navbar .logo{
        width: 50%;
    }
    .hamburger{
        display: flex;
        justify-content: flex-end;
        width: 50%;
    }
    #nav--items ul{
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        align-items: flex-end;
        text-align: left;
        height: 0;
        overflow: hidden;
        transition: all .2s ease-in-out;
    }
    #nav--items ul li{
        margin-bottom: 5rem;
    }
    #nav--items{
        display: block;
        width: 100%;
        height: 0;
        transition: all .3s ease-in-out;
    }


    
    
}

@media only screen and (max-width: 769px) and (max-width: 1024px) { 
    /* styles for tablets here */ 
    .navbar{
        padding:1.5rem 0rem;
        height: auto;

    }
    .navbar h3{
        font-size: 2rem;
        font-weight: 700;
        color: #0070C0;
        cursor: pointer;
    }
    .navbar .container{
        flex-wrap: wrap;
        height: auto;
    }
    .navbar .logo{
        width: 50%;
    }
    .hamburger{
        display: flex;
        justify-content: flex-end;
        width: 50%;
    }
    #nav--items ul{
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        align-items: flex-end;
        text-align: left;
        height: 0;
        overflow: hidden;
        transition: all .5s ease-in-out;
    }
    #nav--items ul li{
        margin-bottom: 5rem;
    }
    #nav--items{
        display: block;
        width: 100%;
        height: 0;
        transition: all .5s ease-in-out;
    }


    
    

}

