.advert{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55rem;
}
.advert .container{ 
    width:80vw;    
    height: 30rem;
    background-color: #0070C0;
    padding: 0 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 2rem;
    box-sizing: border-box;
    
}
.advert h1{
    font-size: 4.5rem;
    color: white;    
}
.advert p{
    font-size: 1.8rem;
    color: white;
    font-weight: 300;    
}
@media only screen and (min-width: 0) and (max-width:768px){
    .advert .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 0px 20px;
        text-align: center;
        height: 30rem;

    }   
    .advert h1{
        font-size: 2.8rem;
        color: white;    
    }
   .advert{
       height: 35rem;
   }
}
@media only screen and (min-width: 430px)  and (max-width:768px){ 
    .advert .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 0px 0px;
        text-align: center;
    } 
    .advert{
        height: 35rem;
    }
   

}